exports.components = {
  "component---src-localized-changelog-index-js": () => import("./../../../src/localized/changelog/index.js" /* webpackChunkName: "component---src-localized-changelog-index-js" */),
  "component---src-localized-guides-index-js": () => import("./../../../src/localized/guides/index.js" /* webpackChunkName: "component---src-localized-guides-index-js" */),
  "component---src-localized-markdown-full-js": () => import("./../../../src/localized/markdown/full.js" /* webpackChunkName: "component---src-localized-markdown-full-js" */),
  "component---src-localized-markdown-narrow-js": () => import("./../../../src/localized/markdown/narrow.js" /* webpackChunkName: "component---src-localized-markdown-narrow-js" */),
  "component---src-localized-quick-starts-index-js": () => import("./../../../src/localized/quick-starts/index.js" /* webpackChunkName: "component---src-localized-quick-starts-index-js" */),
  "component---src-localized-references-endpoint-js": () => import("./../../../src/localized/references/endpoint.js" /* webpackChunkName: "component---src-localized-references-endpoint-js" */),
  "component---src-localized-references-index-js": () => import("./../../../src/localized/references/index.js" /* webpackChunkName: "component---src-localized-references-index-js" */),
  "component---src-localized-references-resource-js": () => import("./../../../src/localized/references/resource.js" /* webpackChunkName: "component---src-localized-references-resource-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-dev-zone-metadata-js": () => import("./../../../src/pages/ai-dev-zone-metadata.js" /* webpackChunkName: "component---src-pages-ai-dev-zone-metadata-js" */),
  "component---src-pages-ai-dev-zone-metadata-structured-js": () => import("./../../../src/pages/ai-dev-zone-metadata-structured.js" /* webpackChunkName: "component---src-pages-ai-dev-zone-metadata-structured-js" */),
  "component---src-pages-ai-dev-zone-summary-js": () => import("./../../../src/pages/ai-dev-zone-summary.js" /* webpackChunkName: "component---src-pages-ai-dev-zone-summary-js" */),
  "component---src-pages-auth-callback-js": () => import("./../../../src/pages/auth/callback.js" /* webpackChunkName: "component---src-pages-auth-callback-js" */),
  "component---src-pages-sample-code-js": () => import("./../../../src/pages/sample-code.js" /* webpackChunkName: "component---src-pages-sample-code-js" */)
}

